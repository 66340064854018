// import { Button } from "bootstrap";
// import { Button } from "bootstrap";
import { Box, Button } from "@mui/material";
import React from "react";
import Header from "./header/Header";
import LoginPage from "./loginPage/LoginPage";
import { useState } from "react";
import LoginModal from "./loginModal/LoginModal";
import { Modal } from "@mui/material";
import healthswim from "./img/heathswim_home (3).svg";
import AppleAppStoreButton from "./img/AppleAppStoreButton.png";
import GooglePlayStoreButton from "./img/GooglePlayStoreButton.png";
import PlaceHolder from "./img/AdobeStock.jpeg";

function App() {
  const [state, setState] = useState(false);
  const handOpenModal = () => {
    setState(true);
  };
  //change these URL before move to production
  const registerpage = () => {
    //stagging
    window.location.href =
      // "https://staging.app.healthswim.com/registerinfoPage";
      //production
      window.location.href = "https://app.healthswim.com/registerinfoPage";
  };
  const loginPage = () => {
    //stagging
    // window.location.href = "https://staging.app.healthswim.com/loginPage";
    //production
    window.location.href = "https://app.healthswim.com/loginPage";
  };
  const privacyPolicyPage = () => {
    //stagging
    // window.open("https://staging.app.healthswim.com/privacyPolicys");
    //production
    window.open("https://app.healthswim.com/privacyPolicys");
  };
  const termsPage = () => {
    //stagging
    // window.open("https://staging.app.healthswim.com/termsAndConditionPage");
    //production
    window.open("https://app.healthswim.com/termsAndConditionPage");
  };
  return (
    <>
      <Header />
      <div className="App">
        <div style={{ position: "relative", textAlign: "center" }}>
          <img
            loading="lazy"
            style={{ height: "auto", paddingTop: "30px", width: "100%" }}
            src={healthswim}
            alt=""
          />
          <div
            style={{
              position: "absolute",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              fontFamily: "Poppins",
              top: "0",
              left: "0",
              right: "0",
              bottom: "0",
            }}
          >
            {/* <p
              style={{
                fontSize: "1.4rem",
                textAlign: "center",
                // marginTop: "3rem",
                marginBottom: "4rem",
                fontWeight: "500",
                color: "#00CCFF",
              }}
            >
              Customized medical information, vetted by health care providers.
            </p> */}
            <h2
              // className="centered"
              style={{
                fontSize: "3rem",
                width: "388px",
                fontWeight: "500",
                fontFamily: "Poppins",
                fontStyle: "normal",
                textTransform: "none",
                letterSpacing: "0rem",
                // marginTop: "209px",
                marginTop: "219px",
              }}
            >
              Trust a Provider Not the Internet
            </h2>
            <button
              onClick={loginPage}
              style={{
                backgroundColor: "#00CCFF",
                border: "none",
                color: "#fff",
                width: "147px",
                height: "48px",
                fontSize: "medium",
                fontFamily: "Poppins",
                // position: "absolute",
                // top: "56%",
                // left: "45%",
                marginTop: "8px",
              }}
            >
              Login
            </button>
            <div
              style={{
                textAlign: "center",
                marginTop: "-1px",
                fontWeight: "600",
                fontSize: "small",
                textTransform: "capitalize",
                marginBottom: "139px",
              }}
            >
              {/* <a href="">Create new account</a> */}
              <button
                className="btnStyle"
                onClick={registerpage}
                style={{
                  fontWeight: "500",
                  fontSize: "small",
                  fontFamily: "Poppins",
                  textTransform: "capitalize",
                }}
              >
                Create new account
              </button>
            </div>
            {/* <div
              style={{
                width: "678px",
                fontFamily: "Poppins",
                marginTop: "70px",
              }}
            >
              <p style={{ fontSize: "1rem", textAlign: "center" }}>
                 Healthswim is your trusted partner in searching the internet for
                credible medical information. Healthswim brings together
                information seekers, like yourself, and licensed healthcare
                providers. When you search for a medical condition, every piece
                of information has been vetted by a provider, preferably your
                own.  
                Customized medical information, vetted by health care providers.
              </p>
            </div> */}
          </div>
          {/* <LoginPage /> */}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "-118px",
            position: "relative",
            zIndex: "999",
          }}
        >
          <a
            href="https://apps.apple.com/us/app/healthswim/id1529080934"
            style={{ padding: "5px" }}
            target="_blank"
            rel="noreferrer"
          >
            <img src={AppleAppStoreButton} alt="" height={"41px"} />
          </a>
          <a
            // href="https://apps.apple.com/us/app/healthswim/id1529080934"
            style={{ padding: "5px" }}
            target="_blank"
            rel="noreferrer"
          >
            <img src={GooglePlayStoreButton} alt="" height={"41px"} />
          </a>
        </div>
      </div>
      {/* <div style={{ display: "flex" }}>
        <img src={PlaceHolder} height="50%" width={"50%"} alt="" />
        <div style={{ width: "40%", height: "100%" }}>
          <h2
            style={{
              fontFamily: "Poppins",
              paddingLeft: "35px",
              fontSize: "2.2rem",
            }}
          >
            What is Healthswim?
          </h2>
          <p
            style={{
              fontSize: "1.3rem",
              // textAlign: "center",
              fontFamily: "Poppins",
              letterSpacing: "0.3px",
              paddingLeft: "35px",
              paddingTop: "70px",
              color: "#656262",
              lineHeight: "40px",
            }}
          >
            Healthswim is your trusted partner in searching the internet for
            credible medical information. Healthswim brings together information
            seekers, like yourself, and licensed healthcare providers. When you
            search for a medical condition on the mobile app, every piece of
            information has been picked by a healthcare provider — preferably
            your own.
          </p>
          <div
            style={{
              display: "flex",
              paddingLeft: "35px",
              paddingTop: "50px",
            }}
          >
            <a
              href="https://apps.apple.com/us/app/healthswim/id1529080934"
              style={{ padding: "5px" }}
              target="_blank"
              rel="noreferrer"
            >
              <img src={AppleAppStoreButton} alt="" height={"41px"} />
            </a>
            <a
              // href="https://apps.apple.com/us/app/healthswim/id1529080934"
              style={{ padding: "5px" }}
              target="_blank"
              rel="noreferrer"
            >
              <img src={GooglePlayStoreButton} alt="" height={"41px"} />
            </a>
          </div>
        </div>
      </div> */}
      <div style={{ marginTop: "23px" }}></div>
      <div className="footer-element">
        <button
          className="btnStyle"
          style={{ margin: "10px", color: "#1c1e21" }}
          onClick={registerpage}
        >
          Sign Up
        </button>{" "}
        <button
          className="btnStyle"
          style={{ margin: "10px", color: "#1c1e21" }}
          onClick={loginPage}
        >
          Login
        </button>{" "}
        <button
          className="btnStyle"
          style={{ margin: "10px", color: "#1c1e21" }}
          onClick={privacyPolicyPage}
        >
          Privacy Policy
        </button>
        <button
          className="btnStyle"
          style={{ margin: "10px", color: "#1c1e21" }}
          onClick={termsPage}
        >
          Terms
        </button>{" "}
      </div>
    </>
  );
}

export default App;
