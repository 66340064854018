import React from "react";
import "./Header.css";
import Hs_logo from "../img/HS_logo.png";
function Header(props) {
  return (
    <div
      style={{
        display: "block",
        position: "fixed",
        background: "white",
        width: "100%",
        zIndex: "2",
        top: "0",
      }}
    >
      <div className="header">
        <div className="innerHeader">
          <img src={Hs_logo} alt="" className="headerImg" />
        </div>
        <div
          style={{
            position: "absolute",
            fontSize: "11px",
            textAlign: "center",
            // marginTop: "3rem",
            marginBottom: "4rem",
            fontWeight: "500",
            color: "#00CCFF",
            display: "flex",
            justifyContent: "flex-end",
            bottom: "0px",
            width: "473px",
            fontFamily: "Poppins",
          }}
        >
          <p
            style={{
              position: "absolute",
              bottom: "-37px",
              marginBottom: "0px",
            }}
          >
            Customized medical information, vetted by health care providers
          </p>
        </div>
      </div>
    </div>
  );
}

export default Header;
